import img from '../../assets/img/logo.png';

const Footer = () => {
  return (
    <footer className="footer_area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="footer_top flex-column">
              <div className="footer_logo">
                <a href="#">
                  <img src={img} alt="" />
                </a>
                <h4>Follow Me</h4>
              </div>
              <div className="footer_social">
                <a href="#"><i className="fa fa-facebook"></i></a>
                <a href="#"><i className="fa fa-twitter"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div className="row footer_bottom justify-content-center">
          <p className="col-lg-8 col-sm-12 footer-text">Copyright All rights reserved | This template is made with by Reeact</p>
        </div>
      </div>
    </footer>
  )
};

export default Footer;