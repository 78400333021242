import { useState } from 'react';
import HeaderMenu from '../HeaderMenu';
import Footer from '../Footer';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    msg: '',
  });
  const [responseMessage, setResponseMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/wp-admin/admin-ajax.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          action: 'general_form_send',
          name: formData.name,
          email: formData.email,
          msg: formData.msg,
        }),
      });

      const result = await response.json();

      setResponseMessage(result.message);
    } catch (error) {
      setResponseMessage(`There was an error sending your message: ${error.message}`);
    }
  };

  return (
    <>
      <header className="header_area">
        <div className="main_menu">
          <HeaderMenu />
        </div>
      </header>
      <section class="banner_area">
        <div class="banner_inner d-flex align-items-center">
          <div class="container">
            <div class="banner_content text-center">
              <h2>Contact Us</h2>
              <div class="page_link">
                <a href="index.html">Home</a>
                <a href="contact.html">Contact</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact_area section_gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <form className="row contact_form" onSubmit={handleSubmit} id="contactForm" noValidate="novalidate">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Your Name"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Your Email"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <textarea
                      name="msg"
                      className="form-control"
                      rows="1"
                      value={formData.msg}
                      onChange={handleChange}
                      placeholder="Your Message"
                    />
                  </div>
                </div>
                <div className="col-md-12 text-right">
                  <button type="submit" value="submit" className="primary_btn">
                    <span>Send Message</span>
                  </button>
                  <div className="modal-message modal-">
                  {responseMessage && <p>{responseMessage}</p>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;