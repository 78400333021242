import img from '../../../assets/img/header-img.png';

const Header = () => {
  return (
    <section className="home_banner_area section_gap_bottom">
      <div className="banner_inner">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="banner_content">
                <h3 className="text-uppercase">Diseño gráfico</h3>
                <h1 className="text-uppercase">Desarrollo Web</h1>
                <h5 className="text-uppercase">y wordpress.</h5>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="home_right_img">
                <img className="" src={img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default Header;