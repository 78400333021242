import HeaderMenu from '../HeaderMenu';
import Header from './partials/Header';
import Services from './partials/Services';
import Portfolio from './partials/Portfolio';
import Footer from '../Footer';

const Home = () => {
  return (
    <header className="header_area">
      <div className="main_menu">
        <HeaderMenu />
        <Header />
        <Services />
        <Portfolio />
        <Footer />
      </div>
    </header>
  )
};

export default Home;
