import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import HeaderMenu from '../HeaderMenu';
import Footer from '../Footer';
import Post from './partials/Post';
import img from '../../assets/img/blog/author.png';

const Blog = () => {
  const { id } = useParams();
  const [posts, setPosts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPosts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/wp-json/wp/v2/posts?_embed`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      const postsWithImages = data.map(post => {
        const featuredImage = post._embedded?.['wp:featuredmedia']?.[0]?.source_url || '';
        return {
          ...post,
          featuredImageUrl: featuredImage
        };
      });

      setPosts(postsWithImages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [id]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error.message}</div>;
  }

  return (
    <header className="header_area">
      <div className="main_menu">
        <HeaderMenu />
        <section className="blog_area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="blog_left_sidebar">
                  {posts && posts.map((post) => (
                    <Post key={post.id} post={post} />
                  ))}
                </div>
              </div>
              <div className="col-lg-4">
              <div className="blog_right_sidebar">
                  <aside className="single_sidebar_widget author_widget">
                      <img className="author_img rounded-circle" src={img} alt="" />
                      <h4>Charlie Barber</h4>
                      <p>Senior blog writer</p>
                      <div className="social_icon">
                          <a href="#"><i className="fa fa-facebook"></i></a>
                          <a href="#"><i className="fa fa-twitter"></i></a>
                          <a href="#"><i className="fa fa-github"></i></a>
                          <a href="#"><i className="fa fa-behance"></i></a>
                      </div>
                      <p>Boot camps have its supporters andit sdetractors. Some people do not understand why you should have to spend money on boot camp when you can get. Boot camps have itssuppor ters andits detractors.</p>
                      <div className="br"></div>
                  </aside>
                  <aside className="single-sidebar-widget newsletter_widget">
                      <h4 className="widget_title">Newsletter</h4>
                      <p>
                      Here, I focus on a range of items and features that we use in life without
                      giving them a second thought.
                      </p>
                      <div className="form-group d-flex flex-row">
                          <div className="input-group">
                              <div className="input-group-prepend">
                                  <div className="input-group-text"><i className="fa fa-envelope" aria-hidden="true"></i></div>
                              </div>
                          </div>
                          <a href="#" className="bbtns">Subcribe</a>
                      </div>	
                      <p className="text-bottom">You can unsubscribe at any time</p>	
                      <div className="br"></div>							
                  </aside>
              </div>
            </div>
            </div>
            
          </div>
        </section>
        <Footer />
      </div>
    </header>
  );
};

export default Blog;