const Post = ({post}) => {  
  return (
    <article className="row blog_item">
      <div className="col-md-3">
        <div className="blog_info text-right">
          <div className="post_tag">
            <a href="#">Food,</a>
            <a className="active" href="#">Technology</a>
          </div>
          <ul className="blog_meta list">
            <li><a href="#">Mark wiens<i className="lnr lnr-user"></i></a></li>
            <li><a href="#">12 Dec, 2017<i className="lnr lnr-calendar-full"></i></a></li>
            <li><a href="#">1.2M Views<i className="lnr lnr-eye"></i></a></li>
            <li><a href="#">06 Comments<i className="lnr lnr-bubble"></i></a></li>
          </ul>
        </div>
      </div>
      <div className="col-md-9">
        <div className="blog_post">
          {post.featuredImageUrl && <img src={post.featuredImageUrl} alt="Imagen destacada" />}
          <div className="blog_details">
            <a href="single-blog.html"><h2>{post.title.rendered}</h2></a>
            <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
            <a href="single-blog.html" className="primary_btn"><span>View More</span></a>
          </div>
        </div>
      </div>
    </article>
  )
};

export default Post;