import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderMenu from '../HeaderMenu';
import Footer from '../Footer';

const Post = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/wp-json/wp/v2/posts/${id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPost(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error.message}</div>;
  }

  return (
    <header className="header_area">
      <div className="main_menu">
        <HeaderMenu />
        <section className="blog_area">
          <div className="container">
            {post && (
              <div>
                <h1 className="h1">{post.title.rendered}</h1>
                <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
              </div>
            )}
          </div>
        </section>
        <Footer />
      </div>
    </header>
  );
};

export default Post;