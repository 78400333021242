const Services = () => {
  return (
    <section className="features_area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <div className="main_title">
              <h2>Servicios</h2>
              <p>Estudio especializado en el desarrollo de temas WordPress a medida y plugins. Trabajamos para agencias de diseño, empresas y freelancers alrededor del mundo.</p>
            </div>
          </div>
        </div>
        <div className="row feature_inner">
          <div className="col-lg-3 col-md-6">
            <div className="feature_item">
              <h4>DISEÑO DE TEMAS Y PLUGINS A MEDIDA PARA WORDPRESS</h4>
              <p>Estudiamos cada caso en concreto para ofrecer proyectos personalizados y escalabilidad del negocio a través de la web. Además, personalizamos tu plantilla para dotar a tu web de las funcionalidades necesarias en tu empresa.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="feature_item">
              <h4>DESARROLLO DE APP´S</h4>
              <p>Aplicaciones a la medida de tu empresa. Estudiamos el caso para ofrecer un producto sencillo y efectivo.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="feature_item">
              <h4>IDENTIDAD CORPORATIVA</h4>
              <p>Ofrecemos un servicio de consultoría de diseño y optimización de la imagen corporativa; junto con los espacios, tipografía y libros de estilo. Tu marca lo es todo.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="feature_item">
              <h4>MARKETING Y COMUNICACIÓN</h4>
              <p>Tenemos las mejores ideas para generar retorno y respuesta activa de tus clientes: marketing y comunicación estratégica, desarrollo integral de campañas, gabinete de prensa, planificación de medios, social media, organización de eventos y acciones específicas.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default Services;