import { useState, useEffect, useRef }  from 'react';
import { Link } from 'react-router-dom';
import Isotope from 'isotope-layout';

const Portfolio = () => {
  const [posts, setPosts] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isotopeInstance = useRef(null);

  useEffect(() => {
    if (posts) {
      isotopeInstance.current = new Isotope('.portfolio-grid', {
        layoutMode: 'fitRows',
        itemSelector: '.all',
				percentPosition: true,
				masonry: {
					columnWidth: '.all'
				}
      });
    }
  }, [posts]);

  const handleFilter = (category) => {
    isotopeInstance.current.arrange({
      filter: category === '*' ? '*' : `.${category}`,
    });
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/wp-json/wp/v2/portfolio?_embed`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPosts(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/wp-json/wp/v2/tipo`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        setError(error);
      }
    };

    fetchPosts();
    fetchCategories();
  }, []);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error.message}</div>;
  }

  return (
    <section className="portfolio_area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="main_title text-left">
              <h2>Portfolio</h2>
              <p>Desde proyectos estratégicos globales, hasta la web corporativa de tu empresa. Escalamos el proyecto en función de tus necesidades.</p>
            </div>
          </div>
        </div>
        <div className="filters portfolio-filter">
          <ul>
            <li onClick={() => handleFilter('*')} key="all">Todos</li>
            {categories.map(category => (
              <li
                onClick={() => handleFilter(category.slug)}
                key={category.id}
              >
                {category.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="filters-content">
          <div className="row portfolio-grid justify-content-center section_gap_bottom">
            {posts && posts.map(post => {
              const featuredMedia = post._embedded['wp:featuredmedia'];
              
              const featuredImageUrl = featuredMedia && featuredMedia.length > 0 
                ? featuredMedia[0].media_details.sizes['btb-featured-image-thumb'] 
                  ? featuredMedia[0].media_details.sizes['btb-featured-image-thumb'].source_url 
                  : featuredMedia[0].source_url
                : '';

              const postCategory = post._embedded['wp:term'][0];

              return (
                <div className={`col-lg-4 col-md-6 all ${postCategory[0].slug}`} key={post.id}>
                  <div className="portfolio_box">
                    <div className="single_portfolio">
                      <img className="img-fluid w-100" src={featuredImageUrl} alt={post.title.rendered} />
                    </div>
                    <div className="short_info">
                      <h4>
                        <Link className="img-gal" to={`/portfolio/${post.id}`}>{post.title.rendered}</Link>
                      </h4>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  )
};

export default Portfolio;