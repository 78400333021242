import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';
import Blog from './components/Blog';
import Post from './components/Post';
import Portfolio from './components/Portfolio';
import NotFound from './components/NotFound';
import './App.scss';
// import './assets/js/theme.js';

const App = () => {
  return (
    <Router basename={'/'}> 
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/post/:id" element={<Post />} />
        <Route path="/portfolio/:id" element={<Portfolio />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  )
};

export default App;